<template>
  <div class="d-flex flex-column flex-root">
    <div class="login login-signin-on login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-color" id="kt_login">
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column flex-row-auto">
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-center bgi-position-x-center center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
          style="background-size: cover"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <img
        src="/media/logos/scooble_logo_white.png"
        alt="Logo"
        style="top: 20px; right: 20px; position: absolute; width: 250px"
      />
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form class="form" novalidate="novalidate" id="kt_login_signin_form">
              <div v-if="!spinner" class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-white font-size-h4 font-size-h1-lg">{{ title }}</h3>
                <h6 class="font-weight-bold text-white font-size-h6">
                  {{ message }}
                </h6>
              </div>
              <div v-else class="pb-13 pt-lg-0 pt-5 d-flex align-items-center justify-content-center">
                <b-spinner variant="warning" label="Spinning"></b-spinner>
              </div>
            </form>
          </div>
          <!--end::Signin-->
        </div>
        <!--begin::Content footer-->

        <div class="d-flex justify-content-lg-start justify-content-center p-7 py-lg-0">
          <b-row>
            <b-col xl="2" lg="12" md="12" sm="12" cols="12">
              <a href="https://www.scooble.de/impressum/" class="text-white font-weight-bold">Imprint</a></b-col
            >
            <b-col xl="5" lg="12" md="12" sm="12" cols="12">
              <a href="https://www.scooble.de/agb/" class="text-white font-weight-bold">Terms&Conditions</a></b-col
            >
            <b-col xl="5" lg="12" md="12" sm="12" cols="12">
              <a href="https://www.scooble.de/datenschutz/" class="text-white font-weight-bold"
                >Data Protection</a
              ></b-col
            ></b-row
          >
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import '@/assets/sass/pages/login/login-1.scss';
</style>

<script>
import { VERIFY_ACCOUNT } from '@/core/services/store/auth.module'

export default {
  name: 'email-verify',
  data() {
    return {
      title: '',
      message: '',
      spinner: true,
    }
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + 'media/logos/scooble-start-bild.jpg'
    },
  },
  mounted() {
    this.$store
      .dispatch(VERIFY_ACCOUNT, this.$route.params.verificationCode)
      .then(({ data }) => {
        this.spinner = false
        this.title = data.title
        this.message = data.message
      })
      .catch(err => {
        this.spinner = false
        const { response } = err
        if (response) {
          this.title = response.data.message.title
          this.message = response.data.message.message
        } else {
          this.title = ''
          this.message = 'Opps! Something went wrong!'
        }
      })
  },
}
</script>

<style>
.bg-color {
  background: #6954a4;
  background: radial-gradient(circle, #6954a4 9%, #3a2080 100%);
}
.btn-warning {
  background: #e45e00 !important;
  border: #e45e00 !important;
}
</style>
